.App {
  text-align: center;
  align-content: center;
  justify-items: center;
  min-height: 100dvh;
}

.bg-home {
  background-color: rgba(244, 55, 0, 1);
  background: url("https://cdn.kar-tini.id/bg_c943118484.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.trapezoid {
  width: 100%;
  height: 50px;
  background: linear-gradient(
    to bottom,
    rgba(244, 55, 0, 1) 0%,
    rgba(244, 55, 0, 0.5) 20%,
    white 100%
  );
  clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
  overflow: hidden;
}
